:root {
  --amplify-components-authenticator-form-padding: 0;
  --amplify-components-authenticator-container-width-max: 100%;
  --amplify-components-fieldcontrol-focus-border-color: #1565c0;
  --amplify-components-fieldcontrol-focus-box-shadow: 0px 0px 0px 1px var(--amplify-components-fieldcontrol-focus-border-color);
}

.amplify-tabs {
  display: none;
}

[data-amplify-authenticator] [data-amplify-router] {
  border: none;
  box-shadow: none;
}

.amplify-button[data-variation='primary'] {
  background: #1976d2;
}

.amplify-button[data-variation='primary']:hover {
  background: #1565c0;
}

[data-amplify-authenticator-signup] .amplify-flex.amplify-field.amplify-textfield:not(.amplify-passwordfield) {
  width: 48%;
  gap: 0;
}

[data-amplify-authenticator-signup] .amplify-flex.amplify-field.amplify-textfield.amplify-passwordfield {
  width: 100%;
}

.amplify-input {
  padding: 1em 2em;
}

[data-amplify-authenticator-signup] fieldset.amplify-flex > div.amplify-flex {
  flex-direction: row!important;
  flex-wrap: wrap!important;
  column-gap: 0;
  justify-content: space-between;
}