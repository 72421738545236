a, a:visited {
  color: rgba(0, 0, 0, 0.87);
}
a:hover {
  color: #1976d2;
}
a {
  text-decoration: none;
}

.MuiPhoneNumber-flagButton svg {
  max-width: 30px;
  max-height: 20px;
}

.MuiInputBase-multiline {
  overflow-y: auto!important;
}