body .MuiDataGrid-main .MuiDataGrid-columnHeader,
body .MuiDataGrid-main .MuiDataGrid-cell {
  padding: 1.5em;
  height: auto;
  min-height: auto;
  max-height: auto;
}

body .MuiDataGrid-main .MuiDataGrid-row {
  min-height: auto !important;
  max-height: unset !important;
}

body .MuiDataGrid-root {
  height: calc(100vh - 15em);
  min-height: calc(100vh - 15em);
}