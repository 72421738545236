.mainNav {
  display: block;
}

.topNav .subNav {
  display: none;
  background-color: rgba(255,255,255,.2);
}

.subNav .MuiListItemText-primary {
  font-weight: 100;
}
.subNav .MuiListItemButton-gutters {
  padding: 5px 10px;
}

.topNav.active .mainNav {
  border: 1px solid rgba(255,255,255,.3);
  border-bottom: 0px;
}
.topNav:hover .subNav, .topNav.active .subNav {
  display: block;
}